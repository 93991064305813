<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  created() {
    document.title = this.$store.state.appTitle;
    var activeTab = localStorage.getItem("activeTab");
    if (activeTab) {
      this.$store.commit("setCurrentActiveTabCodeBlock", activeTab);
    }
  },
};
</script>

<style>
.v-application--is-ltr .v-treeview-node__content {
  margin-left: 0 !important;
}

.v-treeview-node__prepend {
  min-width: 16px;
}

.v-treeview-node__toggle {
  width: 8px;
}
body {
  letter-spacing: 0.4px !important;
}
</style>