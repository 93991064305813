import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/testfile',
    name: 'testfile',
    component: () => import('../views/test')
  },

  {
    path: '/',
    name: 'Home',
    redirect: '/introduction',
    component: Home,
    children: [
      { path: 'introduction', component: () => import('../views/introduction.vue') },
      { path: 'authentication', component: () => import('../views/authentication.vue') },
      { path: 'portfolio', component: () => import('../views/portfolio.vue') },
      { path: 'orderManagement', component: () => import('../views/orderManagement.vue') },
      { path: 'profile', component: () => import('../views/profile.vue') },
      { path: 'funds', component: () => import('../views/funds.vue') },
      { path: 'postman', component: () => import('../views/postman.vue') },
      { path: 'appendix', component: () => import('../views/appendix.vue') },
      { path: 'htmlButtons', name: 'htmlButtons', component: () => import('../views/htmlButtons.vue') },
      { path: 'vendors', component: () => import('../views/vendors.vue') },
      // { path: 'contractMaster', component: () => import('../views/contractMaster.vue') },
      // { path: 'websocket', component: () => import('../views/websocket.vue') },
      // { path: 'historical', component: () => import('../views/historical.vue') },
      { path: 'rates', component: () => import('../views/rates.vue') },
      // { path: 'libraries', component: () => import('../views/libraries.vue') },
      // { path: 'Terms & Conditions', name: 'terms', component: () => import('../views/terms.vue') }
    ]
  },
  {
    path: '*',
    redirect: '/introduction'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
