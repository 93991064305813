import Vue from 'vue'
import Vuex from 'vuex'
import abLogo from '../assets/sidebarLogo.png'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    buildTime: 'build_05_11_22_15_50',
    brokerName: 'Trustline',
    brokerLogo: abLogo,
    appTitle: 'Trustline API',
    activeTab: 'Introduction',
    isMobileView: false,
    curlCodeBlock: '',
    javaCodeBlock: '',
    jsCodeBlock: '',
    pythonCodeBlock: '',
    csharpCodeBlock: '',
    scrolled: false,
    isScroll: true,
    responseBlock: '',
    errorBlock: '',
    // API ENCRYPTION
    curlAPIEncryption: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">  &quot;userId&quot;: &quot;xxx&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaAPIEncryption: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
	  <span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
	<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
	<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\&quot;userId\&quot;:\&quot;XXXX\&quot;}&quot;</span><span style="color: #d0d0d0">);</span>
	<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
	  <span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span>
	  <span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
	  <span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
	  <span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
	<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
	</pre></div>`,
    jsAPIEncryption: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;userId&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonAPIEncryption: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
  <span style="color: #ed9d13">&quot;userId&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span>
<span style="color: #d0d0d0">})</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpAPIEncryption: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;  &quot;</span><span style="color: #d0d0d0">userId</span><span style="color: #ed9d13">&quot;: &quot;</span><span style="color: #d0d0d0">XXXX</span><span style="color: #ed9d13">&quot;</span>
<span style="color: #ed9d13">&quot; + &quot;</span><span style="color: #a61717; background-color: #e3d2d2">\</span><span style="color: #d0d0d0">n</span><span style="color: #ed9d13">&quot; +</span>
<span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    APIEncryptionResponse: `<div style="background: #162435;overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;userId&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;userData&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;encKey&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;apikey&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;User does not login. Please login&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;loginType&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;version&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;login&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">false</span>
<span style="color: #d0d0d0">}</span></pre></div></pre></div>`,

    APIEncryptionError: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
<span style="color: #6ab825; font-weight: bold">&quot;userId&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXXXX&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;userData&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;encKey&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;apikey&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;API key not available. Please generate API key&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;loginType&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;version&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;login&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">false</span>
<span style="color: #d0d0d0">}</span>
</pre></div>
`,

    logoutFromApiErrorResponse: '',

    logoutFromAllErrorResponse: '',

    // GET SESSION ID
    curlGetSessionId: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">  &quot;userId&quot;:&quot;xxx&quot;,</span>
<span style="color: #ed9d13">  &quot;userData&quot;:&quot;d1b48492ec2830e781a594a5185322499a9eb621358ff3225f232f4a1d3f7aed&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaGetSessionId: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
  <span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\&quot;userId\&quot;:\&quot;XXXX\&quot;,\&quot;userData\&quot;:\&quot;U2FsdGVkX18wKWRDAjdqPced0UptKdLyX3+dTIHd+a0=\&quot;}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
  <span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span>
  <span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
  <span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
  <span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsGetSessionId: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;userId&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;userData&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;U2FsdGVkX18wKWRDAjdqPced0UptKdLyX3+dTIHd+a0=&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonGetSessionId: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
  <span style="color: #ed9d13">&quot;userId&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;userData&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;YYYY&quot;</span>
<span style="color: #d0d0d0">})</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpGetSessionId: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;  &quot;</span><span style="color: #d0d0d0">userId</span><span style="color: #ed9d13">&quot;:&quot;</span><span style="color: #d0d0d0">XXXX</span><span style="color: #ed9d13">&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;  &quot;</span><span style="color: #d0d0d0">userData</span><span style="color: #ed9d13">&quot;:&quot;</span><span style="color: #d0d0d0">YYYY</span><span style="color: #ed9d13">&quot; + &quot;</span><span style="color: #a61717; background-color: #e3d2d2">\</span><span style="color: #d0d0d0">n</span><span style="color: #ed9d13">&quot; +</span>
<span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    getSessionIdResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;userSession&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Yo0SukLkIXnKI1moqBC464DzcFWMeTi2zfL3Y0S16IPc6WDlaAahH8tPW4oUKmdD7Zm8Upu3ycztxHLyDuSsd6bJzle9Is1QsHFHLf8T8VsDgiulQRBQY1Wdz0KWe0DQoHcU2cKGcQVYW6u6GbibGpxJfHieOqaWN1c7sJfCNvgh1ekhA8BSrJA7K2ijC6HnTXVzv7BxaEjqFBIYuFGcPV7YgHq3OJYvW23f8RSTQHb6LX0x7CuZ9yo6AK4ejaFY&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,

    getSessionIdError: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
<span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Invalid Input&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>
`,
    // SEARCH SCRIPS
    curlSearchScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">	&quot;symbol&quot;:&quot;tcs&quot;,</span>
<span style="color: #ed9d13">	&quot;exchange&quot;: [&quot;All&quot;, &quot;NSE&quot;,&quot;BSE&quot;, &quot;CDS&quot;, &quot;MCX&quot;, &quot;NFO&quot;]</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaSearchScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\n\t\&quot;symbol\&quot;:\&quot;tcs\&quot;,\n\t\&quot;exchange\&quot;: [\&quot;All\&quot;, \&quot;NSE\&quot;,\&quot;BSE\&quot;, \&quot;CDS\&quot;, \&quot;MCX\&quot;, \&quot;NFO\&quot;]\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsSearchScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;tcs&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;exchange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #d0d0d0">[</span>
    <span style="color: #ed9d13">&quot;All&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;BSE&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;CDS&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;MCX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;NFO&quot;</span>
  <span style="color: #d0d0d0">]</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonSearchScrips: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
  <span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;tcs&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;exchange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #d0d0d0">[</span>
    <span style="color: #ed9d13">&quot;All&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;BSE&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;CDS&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;MCX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;NFO&quot;</span>
  <span style="color: #d0d0d0">]</span>
<span style="color: #d0d0d0">})</span>
<span style="color: #d0d0d0">headers</span><span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
<span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
<span style="color: #ed9d13">'Authorization'</span><span style="color: #d0d0d0">:</span><span style="color: #ed9d13">'Bearer USER_ID SESSION_TOKEN'</span><span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpSearchScrips: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;	&quot;&quot;symbol&quot;&quot;:&quot;&quot;tcs&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;	&quot;&quot;exchange&quot;&quot;: [&quot;&quot;All&quot;&quot;, &quot;&quot;NSE&quot;&quot;,&quot;&quot;BSE&quot;&quot;, &quot;&quot;CDS&quot;&quot;, &quot;&quot;MCX&quot;&quot;, &quot;&quot;NFO&quot;&quot;]&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    searchScripsResponse: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #6ab825; font-weight: bold">&quot;exchange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #d0d0d0">[</span>
        <span style="color: #ed9d13">&quot;All&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;BSE&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;CDS&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;MCX&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;NFO&quot;</span>
    <span style="color: #d0d0d0">],</span>
    <span style="color: #6ab825; font-weight: bold">&quot;Symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;IN&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,
    // MARKET WATCH NAMES LIST
    curlMwList: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">GET</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;&#39;</span>
</pre></div>`,
    javaMwList: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsMwList: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;GET&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonMwList: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;&quot;</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpMwList: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"> <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.GET);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    mwListResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;values&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #d0d0d0">[</span>
        <span style="color: #ed9d13">&quot;new&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;mwGrpWh&quot;</span>
    <span style="color: #d0d0d0">],</span>
    <span style="color: #ed9d13">&quot;MaxMWCount&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">100</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;logindefaultmw&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;new&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,
    // MARKET WATCH LISTS
    curlMwScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span>  <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">&quot;mwName&quot;:&quot;mwGrpFd&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaMwScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\n\&quot;mwName\&quot;:\&quot;mwGrpFd\&quot;\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsMwScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;mwName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;mwGrpFd&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonMwScrips: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
  <span style="color: #ed9d13">&quot;mwName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;mwGrpFd&quot;</span>
<span style="color: #d0d0d0">})</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpMwScrips: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;mwName&quot;&quot;:&quot;&quot;mwGrpFd&quot;&quot;&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    mwScripsResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;values&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #d0d0d0">[</span>
        <span style="color: #d0d0d0">{</span>
            <span style="color: #ed9d13">&quot;tcksize&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;5&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;openinterest&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;optiontype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XX&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;BestSellPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;ExchSeg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;nse_cm&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;defmktproval&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;3&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;CombinedSymbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;symbolname&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;PAYTM&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;BestBuySize&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;664&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;noMktPro&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;mktpro&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;high&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;676.90&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;lasttradedtime&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;01/06/2022 15:59:48&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;BestSellSize&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;low&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;648.30&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;averageprice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;lasttradedquantity&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;50&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;strikeprice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;close&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;654.40&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Expiry&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1 Jan, 1980&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;TradSym&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;PAYTM-EQ&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;spotprice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;BestBuyPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;654.40&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;scripRemarks&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;totalbuyqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;664&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;multiplier&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;totalsellqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;ltp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;654.40&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Change&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;decimalPrecision&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">2</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;token&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;6705&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Exchange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Series&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;EQ&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;corporateaction&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;UniqueKey&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;PAYTM&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;PerChange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;companyname&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ONE 97 COMMUNICATIONS LTD&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Instrument&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;TradeVolume&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;3701972&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;bodlot&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;maxQty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">999999999</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;open&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;656.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;expdate&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;discQty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;10&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;minQty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span>
        <span style="color: #d0d0d0">}</span>
    <span style="color: #d0d0d0">],</span>
    <span style="color: #ed9d13">&quot;ScripCount&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;indexvalues&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #d0d0d0">[</span>
        <span style="color: #d0d0d0">{</span>
            <span style="color: #ed9d13">&quot;indexName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;SENSEX&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;indexPerChange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;-00.33&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;indexChange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;-185.24&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;indexVal&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;55381.17&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;indexExch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;bse_cm&quot;</span>
        <span style="color: #d0d0d0">},</span>
        <span style="color: #d0d0d0">{</span>
            <span style="color: #ed9d13">&quot;indexName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Nifty 50&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;indexPerChange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;indexChange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;indexVal&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;16522.75&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;indexExch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;nse_cm&quot;</span>
        <span style="color: #d0d0d0">}</span>
    <span style="color: #d0d0d0">]</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,

    mwScripsErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
<span style="color: #6ab825; font-weight: bold">&quot;emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not able to Retrieve MarketWatch &quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,

    // ADD SCRIPT
    curlAddScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">	&quot;mwName&quot;:&quot;mwGrpeN&quot;,</span>
<span style="color: #ed9d13">	&quot;exch&quot;:&quot;NSE&quot;,</span>
<span style="color: #ed9d13">	&quot;symbol&quot;:&quot;212&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaAddScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\n\t\&quot;mwName\&quot;:\&quot;mwGrpeN\&quot;,\n\t\&quot;exch\&quot;:\&quot;NSE\&quot;,\n\t\&quot;symbol\&quot;:\&quot;212\&quot;\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsAddScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;mwName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;mwGrpeN&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonAddScrips: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
  <span style="color: #ed9d13">&quot;mwName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;mwGrpeN&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span>
<span style="color: #d0d0d0">})</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpAddScrips: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;	&quot;&quot;mwName&quot;&quot;:&quot;&quot;mwGrpeN&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;	&quot;&quot;exch&quot;&quot;:&quot;&quot;NSE&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;	&quot;&quot;symbol&quot;&quot;:&quot;&quot;212&quot;&quot;&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    addScripsResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Result&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;success&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,

    mwListErrorResponse: '',
    addScripsErrorResponse: '',
    // DELETE SCRIPT
    curlDeleteScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">	&quot;mwName&quot;:&quot;mwGrpeN&quot;,</span>
<span style="color: #ed9d13">	&quot;exch&quot;:&quot;NSE&quot;,</span>
<span style="color: #ed9d13">	&quot;symbol&quot;:&quot;212&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaDeleteScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
    <span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\n\t\&quot;mwName\&quot;:\&quot;mwGrpeN\&quot;,\n\t\&quot;exch\&quot;:\&quot;NSE\&quot;,\n\t\&quot;symbol\&quot;:\&quot;212\&quot;\n}&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsDeleteScrips: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;mwName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;mwGrpeN&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonDeleteScrips: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
<span style="color: #ed9d13">&quot;mwName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;mwGrpeN&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span>
<span style="color: #d0d0d0">})</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
<span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span><span style="color: #d0d0d0">,</span>
<span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpDeleteScrips: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;	&quot;&quot;mwName&quot;&quot;:&quot;&quot;mwGrpeN&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;	&quot;&quot;exch&quot;&quot;:&quot;&quot;NSE&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;	&quot;&quot;symbol&quot;&quot;:&quot;&quot;212&quot;&quot;&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    deleteScripsResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Result&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;success&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,

    deleteScripsErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
<span style="color: #6ab825; font-weight: bold">&quot;emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not able to Retrieve DeleteMWScrips &quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,

    // SCRIP QUOTE DETAILS
    curlScripsDetails: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">	&quot;exch&quot;:&quot;NSE&quot;,</span>
<span style="color: #ed9d13">	&quot;symbol&quot;:&quot;212&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaScripsDetails: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\n\t\&quot;exch\&quot;:\&quot;NSE\&quot;,\n\t\&quot;symbol\&quot;:\&quot;212\&quot;\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsScripsDetails: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span><span style="color: #ed9d13">&quot;Bearer  userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonScripsDetails: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
  <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span>
<span style="color: #d0d0d0">})</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpScripsDetails: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;	&quot;&quot;exch&quot;&quot;:&quot;&quot;NSE&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;	&quot;&quot;symbol&quot;&quot;:&quot;&quot;212&quot;&quot;&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    scripsDetailsResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;optiontype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;SQty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;vwapAveragePrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;139.10&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;LTQ&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;DecimalPrecision&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">2</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;openPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;138.90&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;LTP&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;139.90&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Ltp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;139.90&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;BRate&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;139.90&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;defmktproval&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;3&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;symbolname&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;noMktPro&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;BQty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">25602</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;mktpro&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;LTT&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;01/06/2022 15:56:38&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;TickSize&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;5&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Multiplier&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;strikeprice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;TotalSell&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;High&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;141.45&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;yearlyLowPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;93.20&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;yearlyHighPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;153.50&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;exchFeedTime&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;01-Jun-2022 17:29:40&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;BodLotQty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;PrvClose&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;139.90&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Change&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;SRate&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Series&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;EQ&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;TotalBuy&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;25602&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Low&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;137.05&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;UniqueKey&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;PerChange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;companyname&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOK LEYLAND LTD&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;TradeVolume&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;20070736&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;TSymbl&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Exp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;LTD&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,

    scripsDetailsErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
<span style="color: #6ab825; font-weight: bold">&quot;emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;No data available for selected scrip&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>
`,
    squareOfPositionErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
<span style="color: #6ab825; font-weight: bold">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;Emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not able to Retrieve SquareOffPosition &quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;token&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>
`,
    // PLACE BRACKET ORDER
    curlPlaceBracketOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;[</span>
<span style="color: #ed9d13">  {</span>
<span style="color: #ed9d13">    &quot;complexty&quot;: &quot;bo&quot;,</span>
<span style="color: #ed9d13">    &quot;discqty&quot;: &quot;0&quot;,</span>
<span style="color: #ed9d13">    &quot;exch&quot;: &quot;NSE&quot;,</span>
<span style="color: #ed9d13">    &quot;pCode&quot;: &quot;MIS&quot;,</span>
<span style="color: #ed9d13">    &quot;prctyp&quot;: &quot;L&quot;,</span>
<span style="color: #ed9d13">    &quot;price&quot;: &quot;1454.9&quot;,</span>
<span style="color: #ed9d13">    &quot;qty&quot;: 8,</span>
<span style="color: #ed9d13">    &quot;ret&quot;: &quot;DAY&quot;,</span>
<span style="color: #ed9d13">    &quot;stopLoss&quot;: 28.8,</span>
<span style="color: #ed9d13">    &quot;symbol_id&quot;: &quot;13611&quot;,</span>
<span style="color: #ed9d13">    &quot;target&quot;: 28.0,</span>
<span style="color: #ed9d13">    &quot;trading_symbol&quot;: &quot;IRCTC-EQ&quot;,</span>
<span style="color: #ed9d13">    &quot;trailing_stop_loss&quot;: 3.5,</span>
<span style="color: #ed9d13">    &quot;transtype&quot;: &quot;buy&quot;,</span>
<span style="color: #ed9d13">    &quot;trigPrice&quot;: &quot;1450.9&quot;,</span>
<span style="color: #ed9d13">    &quot;orderTag&quot;: &quot;order1&quot;</span>
<span style="color: #ed9d13">  }</span>
<span style="color: #ed9d13">]&#39;</span>
</pre></div>`,
    javaPlaceBracketOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;[\r\n  {\r\n    \&quot;complexty\&quot;: \&quot;bo\&quot;,\r\n    \&quot;discqty\&quot;: \&quot;0\&quot;,\r\n    \&quot;exch\&quot;: \&quot;NSE\&quot;,\r\n    \&quot;pCode\&quot;: \&quot;MIS\&quot;,\r\n    \&quot;prctyp\&quot;: \&quot;L\&quot;,\r\n    \&quot;price\&quot;: \&quot;1454.9\&quot;,\r\n    \&quot;qty\&quot;: 8,\r\n    \&quot;ret\&quot;: \&quot;DAY\&quot;,\r\n    \&quot;stopLoss\&quot;: 28.8,\r\n    \&quot;symbol_id\&quot;: \&quot;13611\&quot;,\r\n    \&quot;target\&quot;: 28.0,\r\n    \&quot;trading_symbol\&quot;: \&quot;IRCTC-EQ\&quot;,\r\n    \&quot;trailing_stop_loss\&quot;: 3.5,\r\n    \&quot;transtype\&quot;: \&quot;buy\&quot;,\r\n    \&quot;trigPrice\&quot;: \&quot;1450.9\&quot;,\r\n   \&quot;orderTag\&quot;: \&quot;order1\&quot;\r\n }\r\n]&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsPlaceBracketOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify([</span>
  <span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;complexty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;bo&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;discqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MIS&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;prctyp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;L&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;price&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1454.9&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">8</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;ret&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;DAY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;stopLoss&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">28.8</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;symbol_id&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;13611&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;target&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">28</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trading_symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;IRCTC-EQ&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trailing_stop_loss&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">3.5</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;transtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;buy&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trigPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1450.9&quot;,</span>
    <span style="color: #ed9d13">&quot;orderTag&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;order1&quot;</span>
  <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonPlaceBracketOrder: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
    <span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
    <span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
    <span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps([</span>
      <span style="color: #d0d0d0">{</span>
        <span style="color: #ed9d13">&quot;complexty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;bo&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;discqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;mis&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;prctyp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;SL&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;price&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1454.9&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">8</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ret&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;DAY&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;stopLoss&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">28.8</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;symbol_id&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;13611&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;target&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">28</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;trading_symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;IRCTC-EQ&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;trailing_stop_loss&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">3.5</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;transtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;buy&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;trigPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1450.9&quot;,</span>
        <span style="color: #ed9d13">&quot;orderTag&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;order1&quot;</span>
      <span style="color: #d0d0d0">}</span>
    <span style="color: #d0d0d0">])</span>
    <span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
      <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span><span style="color: #d0d0d0">,</span>
      <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
    <span style="color: #d0d0d0">}</span>
    <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
    <span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
    </pre></div>`,
    csharpPlaceBracketOrder: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
    <span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
    <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
    <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;[</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;  {</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;complexty&quot;&quot;: &quot;&quot;bo&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;discqty&quot;&quot;: &quot;&quot;0&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;exch&quot;&quot;: &quot;&quot;NSE&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;pCode&quot;&quot;: &quot;&quot;mis&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;prctyp&quot;&quot;: &quot;&quot;SL&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;price&quot;&quot;: &quot;&quot;1454.9&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;qty&quot;&quot;: 8,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;ret&quot;&quot;: &quot;&quot;DAY&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;stopLoss&quot;&quot;: 28.8,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;symbol_id&quot;&quot;: &quot;&quot;13611&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;target&quot;&quot;: 28.0,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;trading_symbol&quot;&quot;: &quot;&quot;IRCTC-EQ&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;trailing_stop_loss&quot;&quot;: 3.5,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;transtype&quot;&quot;: &quot;&quot;buy&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;trigPrice&quot;&quot;: &quot;&quot;1450.9&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;    &quot;&quot;orderTag&quot;&quot;: &quot;&quot;order1&quot;&quot;</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+ <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;  }</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;]&quot;</span><span style="color: #d0d0d0">;</span>
    <span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
    <span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
    <span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
    </pre></div>
    `,
    placeBracketOrderResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">[</span>
    <span style="color: #d0d0d0">{</span>
        <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;NOrdNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;220601000204792&quot;</span>
    <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]</span>
</pre></div>`,
    // PLACE MARKET ORDER
    curlPlaceMarketOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;[</span>
<span style="color: #ed9d13">  {</span>
<span style="color: #ed9d13">    &quot;complexty&quot;: &quot;regular&quot;,</span>
<span style="color: #ed9d13">    &quot;discqty&quot;: &quot;0&quot;,</span>
<span style="color: #ed9d13">    &quot;exch&quot;: &quot;NSE&quot;,</span>
<span style="color: #ed9d13">    &quot;pCode&quot;: &quot;MIS&quot;,</span>
<span style="color: #ed9d13">    &quot;prctyp&quot;: &quot;MKT&quot;,</span>
<span style="color: #ed9d13">    &quot;price&quot;: &quot;20&quot;,</span>
<span style="color: #ed9d13">    &quot;qty&quot;: 1,</span>
<span style="color: #ed9d13">    &quot;ret&quot;: &quot;DAY&quot;,</span>
<span style="color: #ed9d13">    &quot;symbol_id&quot;: &quot;212&quot;,</span>
<span style="color: #ed9d13">    &quot;trading_symbol&quot;: &quot;ASHOKLEY-EQ&quot;,</span>
<span style="color: #ed9d13">    &quot;transtype&quot;: &quot;BUY&quot;,</span>
<span style="color: #ed9d13">    &quot;trigPrice&quot;: &quot;35&quot;,</span>
<span style="color: #ed9d13">    &quot;orderTag&quot;: &quot;order1&quot;</span>
<span style="color: #ed9d13">  }</span>
<span style="color: #ed9d13">]&#39;</span>
</pre></div>`,
    javaPlaceMarketOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;[\r\n  {\r\n    \&quot;complexty\&quot;: \&quot;regular\&quot;,\r\n    \&quot;discqty\&quot;: \&quot;0\&quot;,\r\n    \&quot;exch\&quot;: \&quot;NSE\&quot;,\r\n    \&quot;pCode\&quot;: \&quot;MIS\&quot;,\r\n    \&quot;prctyp\&quot;: \&quot;MKT\&quot;,\r\n    \&quot;price\&quot;: \&quot;20\&quot;,\r\n    \&quot;qty\&quot;: 1,\r\n    \&quot;ret\&quot;: \&quot;DAY\&quot;,\r\n    \&quot;symbol_id\&quot;: \&quot;212\&quot;,\r\n    \&quot;trading_symbol\&quot;: \&quot;ASHOKLEY-EQ\&quot;,\r\n    \&quot;transtype\&quot;: \&quot;BUY\&quot;,\r\n    \&quot;trigPrice\&quot;: \&quot;35\&quot;,\r\n   \&quot;orderTag\&quot;: \&quot;order1\&quot;\r\n  }\r\n]&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsPlaceMarketOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify([</span>
  <span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;complexty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;regular&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;discqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MIS&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;prctyp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MKT&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;price&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;20&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;ret&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;DAY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;symbol_id&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trading_symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;transtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;BUY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trigPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;35&quot;,</span>
    <span style="color: #ed9d13">&quot;orderTag&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;order1&quot;</span>
  <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonPlaceMarketOrder: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%">	<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
  <span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
  <span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
  <span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
    <span style="color: #ed9d13">&quot;discqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;filledQuantity&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;nestOrderNumber&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;191015000018737&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;prctyp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MKT&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;price&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;20&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trading_symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trigPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;transtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;BUY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MIS&quot;,</span>
    <span style="color: #ed9d13">&quot;orderTag&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;order1&quot;</span>
  <span style="color: #d0d0d0">})</span>
  <span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
  <span style="color: #d0d0d0">}</span>
  <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
  <span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
  </pre></div>`,
    csharpPlaceMarketOrder: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
  <span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
  <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
  <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
  <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
  <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;[</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;  {</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;complexty&quot;&quot;: &quot;&quot;regular&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;discqty&quot;&quot;: &quot;&quot;0&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;exch&quot;&quot;: &quot;&quot;NSE&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;pCode&quot;&quot;: &quot;&quot;mis&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;prctyp&quot;&quot;: &quot;&quot;MKT&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;price&quot;&quot;: &quot;&quot;&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;qty&quot;&quot;: 1,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;ret&quot;&quot;: &quot;&quot;DAY&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;symbol_id&quot;&quot;: &quot;&quot;212&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;trading_symbol&quot;&quot;: &quot;&quot;ASHOKLEY-EQ&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;transtype&quot;&quot;: &quot;&quot;BUY&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;trigPrice&quot;&quot;: &quot;&quot;&quot;&quot;,</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span><span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;    &quot;&quot;orderTag&quot;&quot;: &quot;&quot;order1&quot;&quot;</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span><span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;  }</span>
  <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
  <span style="color: #ed9d13">@&quot;]&quot;</span><span style="color: #d0d0d0">;</span>
  <span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
  <span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
  <span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
  </pre></div>
  `,
    placeMarketOrderResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">[</span>
    <span style="color: #d0d0d0">{</span>
        <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;NOrdNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;220601000204792&quot;</span>
    <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]</span>
</pre></div>`,
    // PLACE ORDER
    curlExecuteOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span>  <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;[</span>
<span style="color: #ed9d13">  {</span>
<span style="color: #ed9d13">    &quot;complexty&quot;: &quot;regular&quot;,</span>
<span style="color: #ed9d13">    &quot;discqty&quot;: &quot;0&quot;,</span>
<span style="color: #ed9d13">    &quot;exch&quot;: &quot;NSE&quot;,</span>
<span style="color: #ed9d13">    &quot;pCode&quot;: &quot;MIS&quot;,</span>
<span style="color: #ed9d13">    &quot;prctyp&quot;: &quot;L&quot;,</span>
<span style="color: #ed9d13">    &quot;price&quot;: &quot;3550.00&quot;,</span>
<span style="color: #ed9d13">    &quot;qty&quot;: 1,</span>
<span style="color: #ed9d13">    &quot;ret&quot;: &quot;DAY&quot;,</span>
<span style="color: #ed9d13">    &quot;symbol_id&quot;: &quot;212&quot;,</span>
<span style="color: #ed9d13">    &quot;trading_symbol&quot;: &quot;ASHOKLEY-EQ&quot;,</span>
<span style="color: #ed9d13">    &quot;transtype&quot;: &quot;BUY&quot;,</span>
<span style="color: #ed9d13">    &quot;trigPrice&quot;: &quot;00.00&quot;,</span>
<span style="color: #ed9d13">    &quot;orderTag&quot;: &quot;order1&quot;</span>
<span style="color: #ed9d13">  }</span>
<span style="color: #ed9d13">]&#39;</span>
</pre></div>`,
    javaExecuteOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;[\r\n  {\r\n    \&quot;complexty\&quot;: \&quot;regular\&quot;,\r\n    \&quot;discqty\&quot;: \&quot;0\&quot;,\r\n    \&quot;exch\&quot;: \&quot;NSE\&quot;,\r\n    \&quot;pCode\&quot;: \&quot;MIS\&quot;,\r\n    \&quot;prctyp\&quot;: \&quot;L\&quot;,\r\n    \&quot;price\&quot;: \&quot;3550.00\&quot;,\r\n    \&quot;qty\&quot;: 1,\r\n    \&quot;ret\&quot;: \&quot;DAY\&quot;,\r\n    \&quot;symbol_id\&quot;: \&quot;212\&quot;,\r\n    \&quot;trading_symbol\&quot;: \&quot;ASHOKLEY-EQ\&quot;,\r\n    \&quot;transtype\&quot;: \&quot;BUY\&quot;,\r\n    \&quot;trigPrice\&quot;: \&quot;00.00\&quot;,\r\n   \&quot;orderTag\&quot;: \&quot;order1\&quot;\r\n }\r\n]&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer  userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsExecuteOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify([</span>
  <span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;complexty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;regular&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;discqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MIS&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;prctyp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;L&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;price&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;3550.00&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;ret&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;DAY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;symbol_id&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trading_symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;transtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;BUY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trigPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;,</span>
    <span style="color: #ed9d13">&quot;orderTag&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;order1&quot;</span>
  <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonExecuteOrder: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps([</span>
  <span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;complexty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;regular&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;discqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MIS&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;prctyp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;L&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;price&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;3550.00&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;ret&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;DAY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;symbol_id&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trading_symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;transtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;BUY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;trigPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;&quot;,</span>
    <span style="color: #ed9d13">&quot;orderTag&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;order1&quot;</span>
  <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">])</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpExecuteOrder: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;[</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;  {</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;complexty&quot;&quot;: &quot;&quot;regular&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;discqty&quot;&quot;: &quot;&quot;0&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;exch&quot;&quot;: &quot;&quot;NSE&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;pCode&quot;&quot;: &quot;&quot;mis&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;prctyp&quot;&quot;: &quot;&quot;L&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;price&quot;&quot;: &quot;&quot;&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;qty&quot;&quot;: 1,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;ret&quot;&quot;: &quot;&quot;DAY&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;symbol_id&quot;&quot;: &quot;&quot;212&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;trading_symbol&quot;&quot;: &quot;&quot;ASHOKLEY-EQ&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;transtype&quot;&quot;: &quot;&quot;BUY&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;trigPrice&quot;&quot;: &quot;&quot;&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    &quot;&quot;orderTag&quot;&quot;: &quot;&quot;order1&quot;&quot;</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;  }</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;]&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    executeOrderResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">[</span>
    <span style="color: #d0d0d0">{</span>
        <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;NOrdNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;220601000204792&quot;</span>
    <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]</span>
</pre></div>`,

    executeOrderErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">[</span>
<span style="color: #d0d0d0">{</span>
    <span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;Emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not able to Retrieve  PlaceOrder &quot;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]</span>
</pre></div>
`,
    placeBracketOrderErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">[</span>
<span style="color: #d0d0d0">{</span>
    <span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;Emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Error Occurred : Trading Symbol Doesn&#39;t exist for the exchange&quot;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]</span>
</pre></div>
`,
    exitBoOrderErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
<span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Error occured&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>
`,

    // ORDER BOOK
    curlOrderBook: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">GET</span> <span style="color: #d0d0d0">&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;&#39;</span>
</pre></div>`,
    javaOrderBook: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsOrderBook: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;GET&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonOrderBook: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
    <span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
    <span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;&quot;</span>
    <span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
    <span style="color: #d0d0d0">}</span>
    <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
    <span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
    </pre></div>`,
    csharpOrderBook: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.GET);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;text/plain&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    orderBookResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">[</span>
    <span style="color: #d0d0d0">{</span>
        <span style="color: #ed9d13">&quot;Prc&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;RequestID&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Cancelqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;discQtyPerc&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;10&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;customText&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Mktpro&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;defmktproval&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;3&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;optionType&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XX&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;usecs&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;596178&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;mpro&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ordergenerationtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;AMO&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Unfilledsize&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;orderAuthStatus&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Usercomments&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ticksize&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;5&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Prctype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;L&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Status&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;after market order req received&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Minqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;orderCriteria&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Sym&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;multiplier&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Exseg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;nse_cm&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ExchOrdID&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ExchConfrmtime&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;--&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;SyomOrderId&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Pcode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MIS&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Dscqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Exchange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Ordvaldate&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;accountId&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;AB066982&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;exchangeuserinfo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;111111111111130&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Avgprc&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Trgprc&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Trantype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;B&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;bqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Fillshares&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Trsym&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;AlgoCategory&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;strikePrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;sipindicator&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;N&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;AlgoID&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;reporttype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;noMktPro&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;BrokerClient&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;--&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;OrderUserMessage&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;decprec&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;2&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ExpDate&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;COPercentage&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0.0</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;marketprotectionpercentage&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;--&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ExpSsbDate&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Nstordno&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;220601000204676&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;OrderedTime&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;01/06/2022 19:30:04&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;modifiedBy&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;--&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;RejReason&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;--&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Scripname&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOK LEYLAND LTD&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;orderentrytime&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;PriceDenomenator&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;panNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;RefLmtPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0.0</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;PriceNumerator&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ordersource&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NEST_REST_WEB&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;token&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;GeneralDenomenator&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Validity&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;DAY&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;series&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;EQ&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;InstName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;GeneralNumerator&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;user&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;AB066982&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Remark&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;order1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;iSinceBOE&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1654092004</span>
    <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]</span>
</pre></div>`,

    placeMarketOrderErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">[</span>
<span style="color: #d0d0d0">{</span>
    <span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;Emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not able to Retrieve  PlaceOrder &quot;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]</span>
</pre></div>
`,

    orderHistoryErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">[</span>
<span style="color: #d0d0d0">{</span>
    <span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;Emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not able to Retrieve  PlaceOrder &quot;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]</span>
</pre></div>`,
    // ORDER HISTORY
    curlOrderHistory: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">  &quot;nestOrderNumber&quot;: &quot;200628000000004&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaOrderHistory: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\r\n  \&quot;nestOrderNumber\&quot;: \&quot;200628000000004\&quot;\r\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsOrderHistory: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;nestOrderNumber&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;200628000000004&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonOrderHistory: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
    <span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
    <span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
    <span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
      <span style="color: #ed9d13">&quot;nestOrderNumber&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;200628000000004&quot;</span>
    <span style="color: #d0d0d0">})</span>
    <span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
      <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span><span style="color: #d0d0d0">,</span>
      <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
    <span style="color: #d0d0d0">}</span>
    <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
    <span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
    </pre></div>`,
    csharpOrderHistory: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
    <span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
    <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
    <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;  &quot;&quot;nestOrderNumber&quot;&quot;: &quot;&quot;200628000000004&quot;&quot;</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
    <span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
    <span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
    <span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
    </pre></div>
    `,
    orderHistoryResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">[</span>
    <span style="color: #d0d0d0">{</span>
        <span style="color: #ed9d13">&quot;Prc&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Action&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;B&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;productcode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MIS&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;reporttype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;triggerprice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;filledShares&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;disclosedqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;exchangetimestamp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;--&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;symbolname&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ExchTimeStamp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;01/06/2022 19:30:04&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;nestordernumber&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;220601000204676&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;duration&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;DAY&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;OrderUserMessage&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;averageprice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ordergenerationtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;AMO&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;modifiedBy&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;--&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;filldateandtime&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;-- --&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Status&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;after market order req received&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;rejectionreason&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;--&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;PriceDenomenator&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;exchangeorderid&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;PriceNumerator&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;legorderindicator&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;customerfirm&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;C&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;ordersource&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NEST_REST_WEB&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;GeneralDenomenator&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;nestreqid&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Ordtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;L&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;unfilledSize&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;scripname&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOK LEYLAND LTD&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;exchange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;GeneralNumerator&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;bqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;Trsym&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span>
    <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]</span>
</pre></div>`,
    // POSITION BOOK
    curlPositionBook: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">  &quot;ret&quot;: &quot;DAY&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaPositionBook: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\r\n  \&quot;ret\&quot;: \&quot;DAY\&quot;\r\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer  userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsPositionBook: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;ret&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;DAY&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonPositionBook: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
    <span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
    <span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
    <span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
      <span style="color: #ed9d13">&quot;ret&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;DAY&quot;</span>
    <span style="color: #d0d0d0">})</span>
    <span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
      <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span><span style="color: #d0d0d0">,</span>
      <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
    <span style="color: #d0d0d0">}</span>
    <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
    <span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
    </pre></div>`,
    csharpPositionBook: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
    <span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
    <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
    <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;  &quot;&quot;ret&quot;&quot;: &quot;&quot;DAY&quot;&quot;</span>
    <span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
    <span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
    <span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
    <span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>`,
    positionBookResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;No Data&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,
    positionBookErrorResponse: '',

    tradeBookErrorResponse: '',

    // FUNDS
    curlFunds: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">GET</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer  userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;&#39;</span>
</pre></div>`,
    javaFunds: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer  userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsFunds: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer  userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;GET&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonFunds: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
    <span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
    <span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;&quot;</span>
    <span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
      <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
    <span style="color: #d0d0d0">}</span>
    <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
    <span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
    </pre></div>`,
    csharpFunds: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
    <span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
    <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.GET);</span>
    <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;&quot;</span><span style="color: #d0d0d0">;</span>
    <span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;text/plain&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
    <span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
    <span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
    </pre></div>`,
    fundsResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">[</span>
    <span style="color: #d0d0d0">{</span>
        <span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ALL&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cncMarginUsed&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;spanmargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;branchAdhoc&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.000000&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;adhocMargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.000000&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;payoutamount&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cdsSpreadBenefit&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;adhocscripmargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;exposuremargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;scripbasketmargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;credits&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;40.89&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;segment&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ALL&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;net&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;40.89&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;turnover&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;grossexposurevalue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;mfssAmountUsed&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;realizedMtomPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;-0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;product&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ALL&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cncSellCrditPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;debits&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;varmargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;multiplier&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;10.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;elm&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;mfamount&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cashmarginavailable&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;40.89&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;brokeragePrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cncRealizedMtomPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;notionalCash&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.000000&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;directcollateralvalue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cncBrokeragePrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;valueindelivery&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;nfoSpreadBenefit&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;losslimit&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;subtotal&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;rmsPayInAmnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;unrealizedMtomPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;-0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;coverOrderMarginPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;exchange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ALL&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;category&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ABFS-COMMON&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;collateralvalue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;rmsIpoAmnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cncUnrealizedMtomPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;premiumPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span>
    <span style="color: #d0d0d0">},</span>
    <span style="color: #d0d0d0">{</span>
        <span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ALL&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cncMarginUsed&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;spanmargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;branchAdhoc&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.000000&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;adhocMargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.000000&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;payoutamount&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cdsSpreadBenefit&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;adhocscripmargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;exposuremargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;scripbasketmargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;credits&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;segment&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;COM&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;net&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;turnover&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;grossexposurevalue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;mfssAmountUsed&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;realizedMtomPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;-0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;product&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ALL&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cncSellCrditPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;debits&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;varmargin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;multiplier&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;elm&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;mfamount&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cashmarginavailable&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;brokeragePrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cncRealizedMtomPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;notionalCash&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.000000&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;directcollateralvalue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cncBrokeragePrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;valueindelivery&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;nfoSpreadBenefit&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;losslimit&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;subtotal&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;rmsPayInAmnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;unrealizedMtomPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;-0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;coverOrderMarginPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;exchange&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ALL&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;category&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NO_VAL&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;collateralvalue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;rmsIpoAmnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;cncUnrealizedMtomPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;premiumPrsnt&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span>
    <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">]</span>
</pre></div>`,

    fundsErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
<span style="color: #6ab825; font-weight: bold">&quot;emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>
`,
    // HOLDINGS
    curlHoldings: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">GET</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer  userId sessionId&#39;</span>
</pre></div>`,
    javaHoldings: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsHoldings: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;GET&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonHoldings: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">payload={}</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpHoldings: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.GET);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    holdingsResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;clientid&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;HoldingVal&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #d0d0d0">[</span>
        <span style="color: #d0d0d0">{</span>
            <span style="color: #ed9d13">&quot;WCqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;BSEHOldingValue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;3960.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;hsflag&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Y&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Series1&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;B&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;HUqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;11&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;YSXHOldingValue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;CSEHOldingValue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Ttrind&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;N&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;DaysMTM&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;csflag&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Y&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;WHqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Pcode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;CNC&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Price&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;BuyQty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Exch4&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Bsetsym&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;BANKBEES&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Exch5&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;LTcse&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;MCXHOldingValue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Holdqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Exch1&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;nse_cm&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Exch2&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;bse_cm&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Exch3&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;LTysx&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Haircut&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Scripcode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;590106&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;LTPValuation&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;NSEHOldingValue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;3954.61&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Ysxtsym&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Ltp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;360.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Coltype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;--&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Btst&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;LTmcxsxcm&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Usedqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Token5&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Nsetsym&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;BANKBEES-EQ&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;CUqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Token2&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;590106&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Token1&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;11439&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Token4&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;SellableQty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;11&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Token3&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Mcxsxcmsym&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Csetsym&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;LTnse&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;359.51&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Series&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;EQ&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Colqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;ExchSeg5&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;ExchSeg2&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;BSE&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;ExchSeg1&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;ExchSeg4&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;LTbse&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;360.00&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;ExchSeg3&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;isin&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;INF204KB15I9&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #ed9d13">&quot;Tprod&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NA&quot;</span>
        <span style="color: #d0d0d0">},</span>
    <span style="color: #d0d0d0">],</span>
    <span style="color: #ed9d13">&quot;Totalval&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #d0d0d0">{</span>
        <span style="color: #ed9d13">&quot;TotalMCXHoldingValue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;TotalCSEHoldingValue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;TotalNSEHoldingValue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;3954.61&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;TotalYSXHoldingValue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0.00&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;TotalBSEHoldingValue&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;3960.00&quot;</span>
    <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,
    // TRADE BOOK
    curlTradeBook: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">GET</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;&#39;</span>
</pre></div>`,
    javaTradeBook: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer  userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsTradeBook: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;GET&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonTradeBook: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;&quot;</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
 <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpTradeBook: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.GET);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;text/plain&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    tradeBookResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;No Data&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,
    // MODIFY ORDER
    curlModifyOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">&quot;discqty&quot;:0,</span>
<span style="color: #ed9d13">&quot;exch&quot;: &quot;NSE&quot;,</span>
<span style="color: #ed9d13">&quot;filledQuantity&quot;:0,</span>
<span style="color: #ed9d13">&quot;nestOrderNumber&quot;: &quot;191015000018737&quot;,</span>
<span style="color: #ed9d13">&quot;prctyp&quot;: &quot;L&quot;,</span>
<span style="color: #ed9d13">&quot;price&quot;: &quot;64&quot;,</span>
<span style="color: #ed9d13">&quot;qty&quot;: 1,</span>
<span style="color: #ed9d13">&quot;trading_symbol&quot;: &quot;ASHOKLEY-EQ&quot;,</span>
<span style="color: #ed9d13">&quot;trigPrice&quot;: &quot;00.00&quot;,</span>
<span style="color: #ed9d13">&quot;transtype&quot;:&quot;BUY&quot;,</span>
<span style="color: #ed9d13">&quot;pCode&quot;:&quot;MKT&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaModifyOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\n\&quot;discqty\&quot;:0,\n\&quot;exch\&quot;: \&quot;NSE\&quot;,\n\&quot;filledQuantity\&quot;:0,\n\&quot;nestOrderNumber\&quot;: \&quot;191015000018737\&quot;,\n\&quot;prctyp\&quot;: \&quot;L\&quot;,\n\&quot;price\&quot;: \&quot;64\&quot;,\n\&quot;qty\&quot;: 1,\n\&quot;trading_symbol\&quot;: \&quot;ASHOKLEY-EQ\&quot;,\n\&quot;trigPrice\&quot;: \&quot;00.00\&quot;,\n\&quot;transtype\&quot;:\&quot;BUY\&quot;,\n\&quot;pCode\&quot;:\&quot;MKT\&quot;\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsModifyOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;discqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;filledQuantity&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;nestOrderNumber&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;191015000018737&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;prctyp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;L&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;price&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;64&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;trading_symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;trigPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;transtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;BUY&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MKT&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonModifyOrder: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%">	<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
  <span style="color: #ed9d13">&quot;discqty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;filledQuantity&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">0</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;nestOrderNumber&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;191015000018737&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;prctyp&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;L&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;price&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;64&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #3677a9">1</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;trading_symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;trigPrice&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;00.00&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;transtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;BUY&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MKT&quot;</span>
<span style="color: #d0d0d0">})</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpModifiOrder: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;discqty&quot;&quot;:0,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;exch&quot;&quot;: &quot;&quot;NSE&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;filledQuantity&quot;&quot;:0,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;nestOrderNumber&quot;&quot;: &quot;&quot;191015000018737&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;prctyp&quot;&quot;: &quot;&quot;L&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;price&quot;&quot;: &quot;&quot;64&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;qty&quot;&quot;: 1,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;trading_symbol&quot;&quot;: &quot;&quot;ASHOKLEY-EQ&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;trigPrice&quot;&quot;: &quot;&quot;00.00&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;transtype&quot;&quot;:&quot;&quot;BUY&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;&quot;&quot;pCode&quot;&quot;:&quot;&quot;MKT&quot;&quot;&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    modifyOrderResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Result&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot; NEST Order Number :220601000204676&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,

    modifyOrderErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
<span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;Emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;No Data&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>
`,
    // SUQARE OFF POSITION
    curlSquareOfPosition: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">  &quot;exchSeg&quot;:&quot;nse_cm&quot;,</span>
<span style="color: #ed9d13">  &quot;pCode&quot;:&quot;MIS&quot;,</span>
<span style="color: #ed9d13">  &quot;netQty&quot;:&quot;0&quot;,</span>
<span style="color: #ed9d13">  &quot;tockenNo&quot;:&quot;212&quot;,</span>
<span style="color: #ed9d13">  &quot;symbol&quot;:&quot;ASHOKLEY&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaSquareOfPosition: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\r\n  \&quot;exchSeg\&quot;:\&quot;nse_cm\&quot;,\r\n  \&quot;pCode\&quot;:\&quot;MIS\&quot;,\r\n  \&quot;netQty\&quot;:\&quot;0\&quot;,\r\n  \&quot;tockenNo\&quot;:\&quot;212\&quot;,\r\n  \&quot;symbol\&quot;:\&quot;ASHOKLEY\&quot;\r\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsSquareOfPosition: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;exchSeg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;nse_cm&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MIS&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;netQty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;0&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;tockenNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonSquareOfPosition: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;&lt;BASE URL&gt;+&lt;URL&gt;&quot;</span><span style="color: #d0d0d0">)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps([</span>
  <span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;scripToken&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;14003&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MIS&quot;</span>
  <span style="color: #d0d0d0">},</span>
  <span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;scripToken&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;212&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;CNC&quot;</span>
  <span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">])</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>
`,
    csharpSquareOfPosition: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(</span><span style="color: #ed9d13">&quot;&lt;BASE URL&gt;+&lt;URL&gt;&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;[</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    {</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;        &quot;&quot;scripToken&quot;&quot;:&quot;&quot;14003&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;        &quot;&quot;pCode&quot;&quot;:&quot;&quot;MIS&quot;&quot;</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    },</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    {</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;         &quot;&quot;scripToken&quot;&quot;:&quot;&quot;212&quot;&quot;,</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;        &quot;&quot;pCode&quot;&quot;:&quot;&quot;CNC&quot;&quot;</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;    }</span>
<span style="color: #ed9d13">&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
<span style="color: #ed9d13">@&quot;]&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    squareOfPositionResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Result&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot; NEST Order Number :220601000204676&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,
    // CANCEL ORDER
    curlCancelOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer  userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">&quot;exch&quot;: &quot;NSE&quot;,</span>
<span style="color: #ed9d13">&quot;nestOrderNumber&quot;: &quot;191015000018737&quot;,</span>
<span style="color: #ed9d13">&quot;trading_symbol&quot;: &quot;ASHOKLEY-EQ&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaCancelOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\n\&quot;exch\&quot;: \&quot;NSE\&quot;,\n\&quot;nestOrderNumber\&quot;: \&quot;191015000018737\&quot;,\n\&quot;trading_symbol\&quot;: \&quot;ASHOKLEY-EQ\&quot;\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsCancelOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer  userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;nestOrderNumber&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;191015000018737&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;trading_symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonCancelOrder: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
    <span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">json</span>
    <span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0;font-style:italic">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
    <span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">json.dumps({</span>
      <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
      <span style="color: #ed9d13">&quot;nestOrderNumber&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;191015000018737&quot;</span><span style="color: #d0d0d0">,</span>
      <span style="color: #ed9d13">&quot;trading_symbol&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;ASHOKLEY-EQ&quot;</span>
    <span style="color: #d0d0d0">})</span>
    <span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
      <span style="color: #ed9d13">&#39;Content-Type&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;application/json&#39;</span><span style="color: #d0d0d0">,</span>
      <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
    <span style="color: #d0d0d0">}</span>
    <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
    <span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
    </pre></div>`,
    csharpCancelOrder: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic">URL&gt;+&lt;URL&gt;)</span>
    <span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
    <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
    <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
    <span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;{&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;&quot;&quot;exch&quot;&quot;: &quot;&quot;NSE&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;&quot;&quot;nestOrderNumber&quot;&quot;: &quot;&quot;191015000018737&quot;&quot;,&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;&quot;&quot;trading_symbol&quot;&quot;: &quot;&quot;ASHOKLEY-EQ&quot;&quot;&quot;</span> <span style="color: #d0d0d0">+</span> <span style="color: #ed9d13">&quot;\n&quot;</span> <span style="color: #d0d0d0">+</span>
    <span style="color: #ed9d13">@&quot;}&quot;</span><span style="color: #d0d0d0">;</span>
    <span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
    <span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
    <span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
    </pre></div>`,
    cancelOrderResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Result&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot; NEST Order Number :220601000204676&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,

    cancelOrderErrorResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
<span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Not_Ok&quot;</span><span style="color: #d0d0d0">,</span>
<span style="color: #6ab825; font-weight: bold">&quot;Emsg&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;No Data&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>
`,
    // POSITION CONVERTION
    curlPositionConvertion: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color: #d0d0d0">&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">    &quot;pCode&quot;: &quot;MIS&quot;,</span>
<span style="color: #ed9d13">    &quot;productToCode&quot;: &quot;NRML&quot;,</span>
<span style="color: #ed9d13">    &quot;exch&quot;: &quot;NSE&quot;,</span>
<span style="color: #ed9d13">    &quot;qty&quot;: &quot;2&quot;,</span>
<span style="color: #ed9d13">    &quot;tsym&quot;: &quot;TCS-EQ&quot;,</span>
<span style="color: #ed9d13">    &quot;transtype&quot;: &quot;B&quot;,</span>
<span style="color: #ed9d13">    &quot;tokenNo&quot;:&quot;1594&quot;,</span>
<span style="color: #ed9d13">    &quot;type&quot;: &quot;D&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaPositionConvertion: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\n    \&quot;pCode\&quot;: \&quot;MIS\&quot;,\n    \&quot;productToCode\&quot;: \&quot;NRML\&quot;,\n    \&quot;exch\&quot;: \&quot;NSE\&quot;,\n    \&quot;qty\&quot;: \&quot;2\&quot;,\n    \&quot;tsym\&quot;: \&quot;TCS-EQ\&quot;,\n    \&quot;transtype\&quot;: \&quot;B\&quot;,\n    \&quot;tokenNo\&quot;:\&quot;1594\&quot;,\n    \&quot;type\&quot;: \&quot;D\&quot;\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style:italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style:italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsPositionConvertion: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;pCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;MIS&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;productToCode&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NRML&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;exch&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSE&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;qty&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;2&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;tsym&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;TCS-EQ&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;transtype&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;B&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;tokenNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;1594&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;type&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;D&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    positionConvertionResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Result&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot; NEST Order Number :220601000204676&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,
    orderBookErrorResponse: '',
    // EXIT BRACKET ORDER
    curlExitBoOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Content-Type: application/json&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;{</span>
<span style="color: #ed9d13">    &quot;nestOrderNumber&quot;:&quot;200626000052824&quot;,</span>
<span style="color: #ed9d13">    &quot;symbolOrderId&quot;:&quot;456789&quot;,</span>
<span style="color: #ed9d13">    &quot;status&quot;:&quot;open&quot;</span>
<span style="color: #ed9d13">}&#39;</span>
</pre></div>`,
    javaExitBoOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;{\r\n    \&quot;nestOrderNumber\&quot;:\&quot;200626000052824\&quot;,\r\n    \&quot;symbolOrderId\&quot;:\&quot;456789\&quot;,\r\n    \&quot;status\&quot;:\&quot;open\&quot;\r\n}&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsExitBoOrder: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Content-Type&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;application/json&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">JSON.stringify({</span>
  <span style="color: #ed9d13">&quot;nestOrderNumber&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;200626000052824&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;symbolOrderId&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;456789&quot;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #ed9d13">&quot;status&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;open&quot;</span>
<span style="color: #d0d0d0">});</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    exitBoOrderResponse: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #ed9d13">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #ed9d13">&quot;Result&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot; NEST Order Number :220601000204676&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,
    // SQUARE OFF ALL
    curlSquareOffAll: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--data-raw</span> <span style="color: #ed9d13">&#39;&#39;</span>
</pre></div>`,
    javaSquareOffAll: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;text/plain&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer  userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsSquareOffAll: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">raw</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">body:</span> <span style="color: #d0d0d0">raw,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonSquareOffAll: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;&lt;BASE URL&gt;+&lt;URL&gt;&quot;</span><span style="color: #d0d0d0">)</span>
<span style="color: #d0d0d0">payload</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;&quot;</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpSquareOffAll: ` <div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(</span><span style="color: #ed9d13">&quot;&lt;BASE URL&gt;+&lt;URL&gt;&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">@&quot;&quot;</span><span style="color: #d0d0d0">;</span>
<span style="color: #d0d0d0">request.AddParameter(</span><span style="color: #ed9d13">&quot;text/plain&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body,</span>  <span style="color: #d0d0d0">ParameterType.RequestBody);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    squareOffResponse: '',
    // LOGOUT FROM API
    curlLogoutFromApi: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span>
</pre></div>`,
    javaLogoutFromApi: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;text/plain&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsLogoutFromApi: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonLogoutFromApi: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #ed9d13">&quot;(&lt;BASE URL&gt;+&lt;URL&gt;)&quot;</span>
<span style="color: #d0d0d0">payload={}</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpLogoutFromApi: `<div style=" background: #162435; overflow:auto;width:auto;  padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    logoutFromApiResponse: '',
    // LOGOUT FROM ALL DEVICES
    curlLogoutFromAll: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">POST</span> <span style="color:#d0d0d0;font-style: italic;">&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">\</span>
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer userId sessionId&#39;</span>
</pre></div>`,
    javaLogoutFromAll: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">MediaType</span> <span style="color: #d0d0d0">mediaType</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">MediaType.</span><span style="color: #bbbbbb">parse</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;text/plain&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">RequestBody</span> <span style="color: #d0d0d0">body</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">RequestBody.</span><span style="color: #bbbbbb">create</span><span style="color: #d0d0d0">(mediaType,</span> <span style="color: #ed9d13">&quot;&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">body)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer  userId sessionId&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsLogoutFromAll: `<div style="background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer userId sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;POST&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch</span><span style="color: #d0d0d0;font-style: italic;">(&lt;BASE</span> <span style="color: #d0d0d0;font-style: italic;">URL&gt;+&lt;URL&gt;</span> <span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonLogoutFromAll: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;</span>
<span style="color: #d0d0d0">payload={}</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer USER_ID SESSION_TOKEN&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;POST&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpLogoutFromAll: `<div style=" background: #162435; overflow:auto;width:auto; padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASE</span> <span style="color: #d0d0d0">URL&gt;+&lt;URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.POST);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer USER_ID SESSION_TOKEN&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    logoutFromAllResponse: '',
    curlAccountDetails: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">curl</span> <span style="color: #d0d0d0">--location</span> <span style="color: #d0d0d0">--request</span> <span style="color: #d0d0d0">GET</span> <span style="color: #d0d0d0">&lt;BASEURL</span> <span style="color: #d0d0d0">+</span> <span style="color: #d0d0d0">URL&gt;</span> 
<span style="color: #d0d0d0">--header</span> <span style="color: #ed9d13">&#39;Authorization: Bearer &lt;userId&gt; &lt;sessionId&gt;&#39;</span>
</pre></div>`,
    javaAccountDetails: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">OkHttpClient</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">OkHttpClient().</span><span style="color: #bbbbbb">newBuilder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Request</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Request.</span><span style="color: #bbbbbb">Builder</span><span style="color: #d0d0d0">()</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">url</span><span style="color: #d0d0d0">(&lt;BASEURL</span> <span style="color: #d0d0d0">+</span> <span style="color: #d0d0d0">URL&gt;)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">method</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #6ab825; font-weight: bold">null</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">addHeader</span><span style="color: #d0d0d0">(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer &lt;userId&gt; &lt;sessionId&gt;&quot;</span><span style="color: #d0d0d0">)</span>
		<span style="color: #d0d0d0">.</span><span style="color: #bbbbbb">build</span><span style="color: #d0d0d0">();</span>
<span style="color: #d0d0d0">Response</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.</span><span style="color: #bbbbbb">newCall</span><span style="color: #d0d0d0">(request).</span><span style="color: #bbbbbb">execute</span><span style="color: #d0d0d0">();</span>
</pre></div>`,
    jsAccountDetails: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">myHeaders</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">Headers();</span>
<span style="color: #d0d0d0">myHeaders.append(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer &lt;userId&gt; &lt;sessionId&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">requestOptions</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #d0d0d0">method:</span> <span style="color: #ed9d13">&#39;GET&#39;</span><span style="color: #d0d0d0">,</span>
  <span style="color: #d0d0d0">headers:</span> <span style="color: #d0d0d0">myHeaders,</span>
  <span style="color: #d0d0d0">redirect:</span> <span style="color: #ed9d13">&#39;follow&#39;</span>
<span style="color: #d0d0d0">};</span>
<span style="color: #d0d0d0">fetch(&lt;BASEURL</span> <span style="color: #d0d0d0">+</span> <span style="color: #d0d0d0">URL&gt;,</span> <span style="color: #d0d0d0">requestOptions)</span>
  <span style="color: #d0d0d0">.then(response</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">response.text())</span>
  <span style="color: #d0d0d0">.then(result</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(result))</span>
  <span style="color: #d0d0d0">.</span><span style="color: #6ab825; font-weight: bold">catch</span><span style="color: #d0d0d0">(error</span> <span style="color: #d0d0d0">=&gt;</span> <span style="color: #d0d0d0">console.log(</span><span style="color: #ed9d13">&#39;error&#39;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">error));</span>
</pre></div>`,
    pythonAccountDetails: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">import</span> <span style="color: #447fcf; text-decoration: underline">requests</span>
<span style="color: #d0d0d0">url</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">&lt;BASEURL</span> <span style="color: #d0d0d0">+</span> <span style="color: #d0d0d0">URL&gt;</span>
<span style="color: #d0d0d0">payload={}</span>
<span style="color: #d0d0d0">headers</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">{</span>
  <span style="color: #ed9d13">&#39;Authorization&#39;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&#39;Bearer &lt;userId&gt; &lt;sessionId&gt;&#39;</span>
<span style="color: #d0d0d0">}</span>
<span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">requests.request(</span><span style="color: #ed9d13">&quot;GET&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #d0d0d0">url,</span> <span style="color: #d0d0d0">headers=headers,</span> <span style="color: #d0d0d0">data=payload)</span>
<span style="color: #6ab825; font-weight: bold">print</span><span style="color: #d0d0d0">(response.text)</span>
</pre></div>`,
    csharpAccountDetails: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">client</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestClient(&lt;BASEURL</span> <span style="color: #d0d0d0">+</span> <span style="color: #d0d0d0">URL&gt;);</span>
<span style="color: #d0d0d0">client.Timeout</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">-</span><span style="color: #3677a9">1</span><span style="color: #d0d0d0">;</span>
<span style="color: #6ab825; font-weight: bold">var</span> <span style="color: #d0d0d0">request</span> <span style="color: #d0d0d0">=</span> <span style="color: #6ab825; font-weight: bold">new</span> <span style="color: #d0d0d0">RestRequest(Method.GET);</span>
<span style="color: #d0d0d0">request.AddHeader(</span><span style="color: #ed9d13">&quot;Authorization&quot;</span><span style="color: #d0d0d0">,</span> <span style="color: #ed9d13">&quot;Bearer &lt;userId&gt; &lt;sessionId&gt;&quot;</span><span style="color: #d0d0d0">);</span>
<span style="color: #d0d0d0">IRestResponse</span> <span style="color: #d0d0d0">response</span> <span style="color: #d0d0d0">=</span> <span style="color: #d0d0d0">client.Execute(request);</span>
<span style="color: #d0d0d0">Console.WriteLine(response.Content);</span>
</pre></div>`,
    accountDetailsResponse: `<div style="background: #162435; overflow:auto;width:auto;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #d0d0d0">{</span>
    <span style="color: #6ab825; font-weight: bold">&quot;accountName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;bankAccountNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;bankName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;HDFC BANK LTD.&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;accountStatus&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Activated&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;dpType&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;NSDL BENEFICIARY&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;sBrokerName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;bankAddress2&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;bankAddress3&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;bankAddres&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;depository&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;CDSL&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;product&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #d0d0d0">[</span>
        <span style="color: #ed9d13">&quot;CNC&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;CO&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;MIS&quot;</span><span style="color: #d0d0d0">,</span>
        <span style="color: #ed9d13">&quot;NRML&quot;</span>
    <span style="color: #d0d0d0">],</span>
    <span style="color: #6ab825; font-weight: bold">&quot;stat&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Ok&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;address&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;panNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;accountType&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;Non-Institutional&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;bankAccountNo2&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;bankAccountNo3&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;exchEnabled&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;bse_cm|mcx_fo|nse_cm|nse_fo|&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;bankName2&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;STATE BANK OF INDIA&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;bankName3&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;STATE BANK OF INDIA&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;dobAccount&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;11/06/1993&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;accountId&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;cellAddr&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;emailAddr&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;bankdetails&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #d0d0d0">[</span>
        <span style="color: #d0d0d0">{</span>
            <span style="color: #6ab825; font-weight: bold">&quot;bankAddres&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #6ab825; font-weight: bold">&quot;bankAccountNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #6ab825; font-weight: bold">&quot;bankName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;HDFC BANK LTD.&quot;</span>
        <span style="color: #d0d0d0">},</span>
        <span style="color: #d0d0d0">{</span>
            <span style="color: #6ab825; font-weight: bold">&quot;bankAddres&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #6ab825; font-weight: bold">&quot;bankAccountNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #6ab825; font-weight: bold">&quot;bankName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;STATE BANK OF INDIA&quot;</span>
        <span style="color: #d0d0d0">},</span>
        <span style="color: #d0d0d0">{</span>
            <span style="color: #6ab825; font-weight: bold">&quot;bankAddres&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #6ab825; font-weight: bold">&quot;bankAccountNo&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
            <span style="color: #6ab825; font-weight: bold">&quot;bankName&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;STATE BANK OF INDIA&quot;</span>
        <span style="color: #d0d0d0">},</span>
        <span style="color: #d0d0d0">{},</span>
        <span style="color: #d0d0d0">{},</span>
        <span style="color: #d0d0d0">{}</span>
    <span style="color: #d0d0d0">],</span>
    <span style="color: #6ab825; font-weight: bold">&quot;dpAccountNumber&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span><span style="color: #d0d0d0">,</span>
    <span style="color: #6ab825; font-weight: bold">&quot;user&quot;</span><span style="color: #d0d0d0">:</span> <span style="color: #ed9d13">&quot;XXXX&quot;</span>
<span style="color: #d0d0d0">}</span>
</pre></div>`,

    // ACCOUNT DETAILS//
    accountDetailsErrorResponse: '',

  },

  mutations: {
    setMobileView (state, payload) {
      state.isMobileView = payload
    },
    setActiveTab (state, tab) {
      this.commit('setIsScroll', false)
      state.activeTab = tab
      localStorage.setItem('activeTab', tab)
      this.commit('setCurrentActiveTabCodeBlock', tab)
    },
    setCurlCodeBlock (state, data) {
      state.curlCodeBlock = data
    },
    setJavaCodeBlock (state, data) {
      state.javaCodeBlock = data
    },
    setJsCodeBlock (state, data) {
      state.jsCodeBlock = data
    },
    setPythonCodeBlock (state, data) {
      state.pythonCodeBlock = data
    },
    setCsharpCodeBlock (state, data) {
      state.csharpCodeBlock = data
    },
    setResponseBlock (state, data) {
      state.responseBlock = data
    },
    setErrorBlock (state, data) {
      state.errorBlock = data
    },
    setCurrentActiveTabCodeBlock (state, data) {
      switch (data) {
        case 'Note Auth':
        case 'API Encryption Key':
          this.commit('setCurlCodeBlock', state.curlAPIEncryption)
          this.commit('setJavaCodeBlock', state.javaAPIEncryption)
          this.commit('setJsCodeBlock', state.jsAPIEncryption)
          this.commit('setPythonCodeBlock', state.pythonAPIEncryption)
          this.commit('setCsharpCodeBlock', state.csharpAPIEncryption)
          this.commit('setResponseBlock', state.APIEncryptionResponse)
          this.commit('setErrorBlock', state.APIEncryptionError)
          break
        case 'SessionId':
          this.commit('setCurlCodeBlock', state.curlGetSessionId)
          this.commit('setJavaCodeBlock', state.javaGetSessionId)
          this.commit('setJsCodeBlock', state.jsGetSessionId)
          this.commit('setPythonCodeBlock', state.pythonGetSessionId)
          this.commit('setCsharpCodeBlock', state.csharpGetSessionId)
          this.commit('setResponseBlock', state.getSessionIdResponse)
          this.commit('setErrorBlock', state.getSessionIdError)
          break
        case 'Search Scrips':
          this.commit('setCurlCodeBlock', state.curlSearchScrips)
          this.commit('setJavaCodeBlock', state.javaSearchScrips)
          this.commit('setJsCodeBlock', state.jsSearchScrips)
          this.commit('setPythonCodeBlock', state.pythonSearchScrips)
          this.commit('setCsharpCodeBlock', state.csharpSearchScrips)
          this.commit('setResponseBlock', state.searchScripsResponse)
          break
        case 'Note MW':
        case 'Market Watch List':
          this.commit('setCurlCodeBlock', state.curlMwList)
          this.commit('setJavaCodeBlock', state.javaMwList)
          this.commit('setJsCodeBlock', state.jsMwList)
          this.commit('setPythonCodeBlock', state.pythonMwList)
          this.commit('setCsharpCodeBlock', state.csharpMwList)
          this.commit('setResponseBlock', state.mwListResponse)
          this.commit('setErrorBlock', state.mwListErrorResponse)
          break
        case 'Market Watch Scrips':
          this.commit('setCurlCodeBlock', state.curlMwScrips)
          this.commit('setJavaCodeBlock', state.javaMwScrips)
          this.commit('setJsCodeBlock', state.jsMwScrips)
          this.commit('setPythonCodeBlock', state.pythonMwScrips)
          this.commit('setCsharpCodeBlock', state.csharpMwScrips)
          this.commit('setResponseBlock', state.mwScripsResponse)
          this.commit('setErrorBlock', state.mwScripsErrorResponse)
          break
        case 'Add scrips':
          this.commit('setCurlCodeBlock', state.curlAddScrips)
          this.commit('setJavaCodeBlock', state.javaAddScrips)
          this.commit('setJsCodeBlock', state.jsAddScrips)
          this.commit('setPythonCodeBlock', state.pythonAddScrips)
          this.commit('setCsharpCodeBlock', state.csharpAddScrips)
          this.commit('setResponseBlock', state.addScripsResponse)
          this.commit('setErrorBlock', state.addScripsErrorResponse)
          break
        case 'Delete scrips':
          this.commit('setCurlCodeBlock', state.curlDeleteScrips)
          this.commit('setJavaCodeBlock', state.javaDeleteScrips)
          this.commit('setJsCodeBlock', state.jsDeleteScrips)
          this.commit('setPythonCodeBlock', state.pythonDeleteScrips)
          this.commit('setCsharpCodeBlock', state.csharpDeleteScrips)
          this.commit('setResponseBlock', state.deleteScripsResponse)
          this.commit('setErrorBlock', state.deleteScripsErrorResponse)
          break
        case 'Scrips details':
          this.commit('setCurlCodeBlock', state.curlScripsDetails)
          this.commit('setJavaCodeBlock', state.javaScripsDetails)
          this.commit('setJsCodeBlock', state.jsScripsDetails)
          this.commit('setPythonCodeBlock', state.pythonScripsDetails)
          this.commit('setCsharpCodeBlock', state.csharpScripsDetails)
          this.commit('setResponseBlock', state.scripsDetailsResponse)
          this.commit('setErrorBlock', state.scripsDetailsErrorResponse)
          break
        case 'Note Portfolio':
        case 'Position Book':
          this.commit('setCurlCodeBlock', state.curlPositionBook)
          this.commit('setJavaCodeBlock', state.javaPositionBook)
          this.commit('setJsCodeBlock', state.jsPositionBook)
          this.commit('setPythonCodeBlock', state.pythonPositionBook)
          this.commit('setCsharpCodeBlock', state.csharpPositionBook)
          this.commit('setResponseBlock', state.positionBookResponse)
          this.commit('setErrorBlock', state.positionBookErrorResponse)
          break
        case 'Holdings':
          this.commit('setCurlCodeBlock', state.curlHoldings)
          this.commit('setJavaCodeBlock', state.javaHoldings)
          this.commit('setJsCodeBlock', state.jsHoldings)
          this.commit('setPythonCodeBlock', state.pythonHoldings)
          this.commit('setCsharpCodeBlock', state.csharpHoldings)
          this.commit('setResponseBlock', state.holdingsResponse)
          break
        case 'Order Book':
          this.commit('setCurlCodeBlock', state.curlOrderBook)
          this.commit('setJavaCodeBlock', state.javaOrderBook)
          this.commit('setJsCodeBlock', state.jsOrderBook)
          this.commit('setPythonCodeBlock', state.pythonOrderBook)
          this.commit('setCsharpCodeBlock', state.csharpOrderBook)
          this.commit('setResponseBlock', state.orderBookResponse)
          this.commit('setErrorBlock', state.orderBookErrorResponse)
          break
        case 'Trade Book':
          this.commit('setCurlCodeBlock', state.curlTradeBook)
          this.commit('setJavaCodeBlock', state.javaTradeBook)
          this.commit('setJsCodeBlock', state.jsTradeBook)
          this.commit('setPythonCodeBlock', state.pythonTradeBook)
          this.commit('setCsharpCodeBlock', state.csharpTradeBook)
          this.commit('setResponseBlock', state.tradeBookResponse)
          this.commit('setErrorBlock', state.tradeBookErrorResponse)
          break
        case 'Modify Order':
          this.commit('setCurlCodeBlock', state.curlModifyOrder)
          this.commit('setJavaCodeBlock', state.javaModifyOrder)
          this.commit('setJsCodeBlock', state.jsModifyOrder)
          this.commit('setPythonCodeBlock', state.pythonModifyOrder)
          this.commit('setCsharpCodeBlock', state.csharpModifiOrder)
          this.commit('setResponseBlock', state.modifyOrderResponse)
          this.commit('setErrorBlock', state.modifyOrderErrorResponse)
          break
        case 'Bracket Order':
          this.commit('setCurlCodeBlock', state.curlPlaceBracketOrder)
          this.commit('setJavaCodeBlock', state.javaPlaceBracketOrder)
          this.commit('setJsCodeBlock', state.jsPlaceBracketOrder)
          this.commit('setPythonCodeBlock', state.pythonPlaceBracketOrder)
          this.commit('setCsharpCodeBlock', state.csharpPlaceBracketOrder)
          this.commit('setResponseBlock', state.placeBracketOrderResponse)
          this.commit('setErrorBlock', state.placeBracketOrderErrorResponse)
          break
        case 'Square of Position':
          this.commit('setCurlCodeBlock', state.curlSquareOfPosition)
          this.commit('setJavaCodeBlock', state.javaSquareOfPosition)
          this.commit('setJsCodeBlock', state.jsSquareOfPosition)
          this.commit('setPythonCodeBlock', state.pythonSquareOfPosition)
          this.commit('setCsharpCodeBlock', state.csharpSquareOfPosition)
          this.commit('setResponseBlock', state.squareOfPositionResponse)
          this.commit('setErrorBlock', state.squareOfPositionErrorResponse)
          break
        //
        case 'Note OM':
        case 'Place Order':
          this.commit('setCurlCodeBlock', state.curlExecuteOrder)
          this.commit('setJavaCodeBlock', state.javaExecuteOrder)
          this.commit('setJsCodeBlock', state.jsExecuteOrder)
          this.commit('setPythonCodeBlock', state.pythonExecuteOrder)
          this.commit('setCsharpCodeBlock', state.csharpExecuteOrder)
          this.commit('setResponseBlock', state.executeOrderResponse)
          this.commit('setErrorBlock', state.executeOrderErrorResponse)
          break
        case 'Position Convertion':
          this.commit('setCurlCodeBlock', state.curlPositionConvertion)
          this.commit('setJavaCodeBlock', state.javaPositionConvertion)
          this.commit('setJsCodeBlock', state.jsPositionConvertion)
          this.commit('setPythonCodeBlock', '')
          this.commit('setCsharpCodeBlock', '')
          this.commit('setResponseBlock', state.positionConvertionResponse)
          break
        case 'Note Funds':
        case 'Get Limits':
          this.commit('setCurlCodeBlock', state.curlFunds)
          this.commit('setJavaCodeBlock', state.javaFunds)
          this.commit('setJsCodeBlock', state.jsFunds)
          this.commit('setPythonCodeBlock', state.pythonFunds)
          this.commit('setCsharpCodeBlock', state.csharpFunds)
          this.commit('setResponseBlock', state.fundsResponse)
          this.commit('setErrorBlock', state.fundsErrorResponse)
          break
        case 'Exit Bracket Order':
          this.commit('setCurlCodeBlock', state.curlExitBoOrder)
          this.commit('setJavaCodeBlock', state.javaExitBoOrder)
          this.commit('setJsCodeBlock', state.jsExitBoOrder)
          this.commit('setResponseBlock', state.exitBoOrderResponse)
          this.commit('setErrorBlock', state.exitBoOrderErrorResponse)
          break
        case 'Market Order':
          this.commit('setCurlCodeBlock', state.curlPlaceMarketOrder)
          this.commit('setJavaCodeBlock', state.javaPlaceMarketOrder)
          this.commit('setJsCodeBlock', state.jsPlaceMarketOrder)
          this.commit('setPythonCodeBlock', state.pythonPlaceMarketOrder)
          this.commit('setCsharpCodeBlock', state.csharpPlaceMarketOrder)
          this.commit('setResponseBlock', state.placeMarketOrderResponse)
          this.commit('setErrorBlock', state.placeMarketOrderErrorResponse)
          break
        case 'Cancel Order':
          this.commit('setCurlCodeBlock', state.curlCancelOrder)
          this.commit('setJavaCodeBlock', state.javaCancelOrder)
          this.commit('setJsCodeBlock', state.jsCancelOrder)
          this.commit('setPythonCodeBlock', state.pythonCancelOrder)
          this.commit('setCsharpCodeBlock', state.csharpCancelOrder)
          this.commit('setResponseBlock', state.cancelOrderResponse)
          this.commit('setErrorBlock', state.cancelOrderErrorResponse)
          break
        case 'Order History':
          this.commit('setCurlCodeBlock', state.curlOrderHistory)
          this.commit('setJavaCodeBlock', state.javaOrderHistory)
          this.commit('setJsCodeBlock', state.jsOrderHistory)
          this.commit('setPythonCodeBlock', state.pythonOrderHistory)
          this.commit('setCsharpCodeBlock', state.csharpOrderHistory)
          this.commit('setResponseBlock', state.orderHistoryResponse)
          this.commit('setErrorBlock', state.orderHistoryErrorResponse)
          break
        case 'Logout From API':
          this.commit('setCurlCodeBlock', state.curlLogoutFromApi)
          this.commit('setJavaCodeBlock', state.javaLogoutFromApi)
          this.commit('setJsCodeBlock', state.jsLogoutFromApi)
          this.commit('setPythonCodeBlock', state.pythonLogoutFromApi)
          this.commit('setCsharpCodeBlock', state.csharpLogoutFromApi)
          this.commit('setResponseBlock', state.logoutFromApiResponse)
          this.commit('setErrorBlock', state.logoutFromApiErrorResponse)
          break
        case 'Logout From All Devices':
          this.commit('setCurlCodeBlock', state.curlLogoutFromAll)
          this.commit('setJavaCodeBlock', state.javaLogoutFromAll)
          this.commit('setJsCodeBlock', state.jsLogoutFromAll)
          this.commit('setPythonCodeBlock', state.pythonLogoutFromAll)
          this.commit('setCsharpCodeBlock', state.csharpLogoutFromAll)
          this.commit('setResponseBlock', state.logoutFromAllResponse)
          this.commit('setErrorBlock', state.logoutFromAllErrorResponse)
          break
        case 'Account Details':
          this.commit('setCurlCodeBlock', state.curlAccountDetails)
          this.commit('setJavaCodeBlock', state.javaAccountDetails)
          this.commit('setJsCodeBlock', state.jsAccountDetails)
          this.commit('setPythonCodeBlock', state.pythonAccountDetails)
          this.commit('setCsharpCodeBlock', state.csharpAccountDetails)
          this.commit('setResponseBlock', state.accountDetailsResponse)
          this.commit('setErrorBlock', state.accountDetailsErrorResponse)
        // case 'Data API':
        //   this.commit('setCurlCodeBlock', state.curlHistoryResponse)
        //   this.commit('setJavaCodeBlock', state.javaHistoricalResponse)
        //   this.commit('setJsCodeBlock', state.javascriptHistoryResponse)
        //   this.commit('setPythonCodeBlock', state.pythonHistoricalResponse)
        //   this.commit('setCsharpCodeBlock', state.csharpHistoricalResponse)
        //   this.commit('setResponseBlock', state.responseHistorical)
        //   this.commit('setErrorBlock', state.errorHistoricalResponse)
        //   break
      }
    },
    setScrolled (state, value) {
      state.scrolled = value
    },
    setIsScroll (state, value) {
      state.isScroll = value
    }
  },
  actions: {
    setActiveTab ({
      commit
    }, tab) {
      commit('setActiveTab', tab)
    },
    async scrollToTop ({
      commit
    }, refName) {
      const element = document.getElementById(refName)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  },
  getters: {
    getActiveTab: (state) => state.activeTab,
    getCurlCodeBlock: (state) => state.curlCodeBlock,
    getJavaCodeBlock: (state) => state.javaCodeBlock,
    getJsCodeBlock: (state) => state.jsCodeBlock,
    getPythonCodeBlock: (state) => state.pythonCodeBlock,
    getCsharpCodeBlock: (state) => state.csharpCodeBlock,
    getResponseBlock: (state) => state.responseBlock,
    getErrorBlock: (state) => state.errorBlock,
    getScrolled: (state) => state.scrolled,
    getIsScroll: (state) => state.isScroll
  },
  modules: {}
})
