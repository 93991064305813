<template>
  <div>
    <v-app id="inspire" v-resize="onMobileSize">
      <v-navigation-drawer mobile-breakpoint="1024" v-model="drawer" app>
        <div class="d-flex align-center justify-center px-7 nav-head"
          style="height:60px;backround:#ededed;border-right:1px solid #ddd">
          <div style="height: 20px;width: 130px">
            <img width="100%" height="100%" :src="$store.state.brokerLogo" alt="image">
          </div>
        </div>
        <div class="mt-15 scroll-height" style="overflow:auto !important">
          <v-treeview class="fsize14" @click="callTo(item)" ref="tree" @update:open="onOpen" @update:active="test"
            :active.sync="active" :items="tree" item-key="name" :open.sync="open" activatable color="light-blue" dense 
            open-on-click transition >
            <template v-slot:prepend="{ item }">
              <div v-if="!item.children">
                <span class="fsize12">#</span>
              </div>
            </template>
          </v-treeview>
        </div>
      </v-navigation-drawer>

      <v-app-bar app flat height="60">
        <v-app-bar-nav-icon v-if="$store.state.isMobileView" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <div class="w-100 text-capitalize font-weight-bold">
          <div>{{ ($router.currentRoute.path == '/htmlButtons' ? 'HTML 5 Buttons' : $router.currentRoute.path ==
          '/historical' ? 'Historical Data API' : $router.currentRoute.path == '/rates' ? 'Rates Limits' :
          $router.currentRoute.path).replace('/', '')
          }}</div>
        </div>

        <div class="d-flex justify-end w-100">
          <a class="d-flex" @click="$router.push('/postman'); $store.dispatch('setActiveTab', 'postman')">
            <div class="px-2 fsize14">Postman Scrips</div>
            <img :src="require('../assets/postmanLogo.svg')" alt="postman-Scrips" />
          </a>
        </div>

      </v-app-bar>
      <v-main>
        <div class="d-flex">
          <div id="main_page" class="main_page"
            :class="$route.path != '/' && $route.path != '/introduction' && $route.path != '/postman' && $route.path != '/appendix' && $route.path != '/vendors' && $route.path != '/htmlButtons' && $route.path != '/contractMaster' && $route.path != '/websocket' && $route.path != '/rates' && $route.path != '/libraries' && $route.path != '/Terms & Conditions' && !$store.state.isMobileView ? 'w-55' : 'w-100'">
            <router-view />
          </div>
          <preview id="preview"
            v-if="$route.path != '/' && $route.path != '/introduction' && $route.path != '/postman' && $route.path != '/appendix' && $route.path != '/vendors' && $route.path != '/htmlButtons' && $route.path != '/contractMaster' && $route.path != '/websocket' && $route.path != '/rates' && $route.path != '/libraries' && $route.path != '/Terms & Conditions'"
            class="pa-5 code_preview" />
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    preview: () => import("../views/preview.vue"),
  },

  data: () => ({
    model: 0,
    drawer: true,
    active: [],
    avatar: null,
    open: [],
    users: [],
    allOpened: false,
    lastOpen: [],
    headTabs: ["Postman Scrips"],
    tree: [
      {
        name: "Introduction",
        children: [
          { id: 32, name: "Generate API Key", route: "/" },
          { id: 33, name: "API Overflow", route: "/" },
        ],
      },
      {
        name: "Authentication",
        children: [
          { id: 1, name: "API Encryption Key", route: "/encryptionkey" },
          { id: 2, name: "SessionId", route: "/" },
          // { id: 34, name: "Logout From API", route: "/" },
          // { id: 35, name: "Logout From All Devices", route: "/" },
          // { id: 36, name: "Note Auth", route: "/" },
        ],
      },
      // {
      //   name: "Market Watch",
      //   children: [
      //     // { id: 3, name: "Search Scrips", route: "/" },
      //     { id: 4, name: "Market Watch List", route: "/" },
      //     { id: 5, name: "Market Watch Scrips", route: "/" },
      //     { id: 6, name: "Add scrips", route: "/" },
      //     { id: 7, name: "Delete scrips", route: "/" },
      //     { id: 8, name: "Scrips details", route: "/" },
      //     // { id: 37, name: "Note MW", route: "/" },
      //   ],
      // },
      {
        name: "Portfolio",
        children: [
          { id: 9, name: "Position Book", route: "/" },
          { id: 10, name: "Holdings", route: "/" },
          // { id: 38, name: "Note Portfolio", route: "/" },
          // { id: 11, name: "Account Details", route: "/" },
        ],
      },
      {
        name: "Order Management",
        children: [
          // { id: 11, name: "Position Convertion", route: "/" },
          { id: 13, name: "Place Order", route: "/" },
          { id: 14, name: "Bracket Order", route: "/" },
          { id: 12, name: "Square of Position", route: "/" },
          { id: 15, name: "Order Book", route: "/" },
          { id: 16, name: "Trade Book", route: "/" },
          { id: 17, name: "Exit Bracket Order", route: "/" },
          { id: 18, name: "Modify Order", route: "/" },
          { id: 19, name: "Market Order", route: "/" },
          { id: 20, name: "Cancel Order", route: "/" },
          { id: 21, name: "Order History", route: "/" },
        ],
      },
      {
        name: "Funds",
        children: [
          { id: 22, name: "Get Limits", route: "/" },
        ],

      },
      {
        name: "Profile",
        children: [{ id: 22, name: "Account Details", route: "/profile" }],
      },
      // {
      //   name: "Profile",
      //   children: [{ id: 23, name: "Account Details", route: "/" }],
      // },
      {
        name: "Vendors",
        children: [
          { id: 27, name: "Introduction ", route: "/" },
          { id: 28, name: "Registration", route: "/" },
          { id: 29, name: "SSO", route: "/" },
        ],
      },
      {
        name: "HTML 5 Buttons",
        children: [
          { id: 27, name: "Brand Button", route: "/" },
          { id: 28, name: "Custom Button", route: "/" },
          { id: 29, name: "Basket Button", route: "/" },
        ],
      },
      // {
      //   name: "Postman",
      //   children: [{ id: 23, name: "Postman Scrips", route: "/" }],
      // },
      // {
      //   name: "JS Publisher",
      //   children: [{ id: 24, name: "JS Publisher Scrips", route: "/" }],
      // },
      // {
      //   name: "Contract Master",
      //   children: [
      //     { id: 29, name: "JSON API Request", route: "/" },
      //     { id: 27, name: "JSON Format", route: "/" },
      //     { id: 28, name: "CSV Format", route: "/" },
      //   ],
      // },
      // {
      //   name: "Contract Master",
      //   children: [
      //     { id: 29, name: "JSON API Request", route: "/" },
      //     { id: 27, name: "JSON Format", route: "/" },
      //     { id: 28, name: "CSV Format", route: "/" },
      //   ],
      // },
      // {
      //   name: "Websocket",
      //   children: [
      //     { id: 30, name: "Introduction  ", route: "/" },
      //     { id: 30, name: "Create Session", route: "/" },
      //     { id: 31, name: "Create Connection", route: "/" },
      //     { id: 32, name: "Subscribe to Market Data", route: "/" },
      //     { id: 33, name: "Unsubscribe to Market Data", route: "/" },
      //     { id: 34, name: "Subscribe to Depth", route: "/" },
      //     { id: 35, name: "Unsubscribe to Depth", route: "/" },
      //   ],
      // },
      // {
      //   name: "Historical Data",
      //   children: [
      //     { id: 30, name: "Data API", route: "/" },
      //   ],
      // },
      {
        name: "Appendix",
        children: [
          { id: 22, name: "Transaction Type", route: "/" },
          { id: 23, name: "Price Type", route: "/" },
          { id: 24, name: "Product Type", route: "/" },
          { id: 25, name: "Segment Type", route: "/" },
          // { id: 22, name: "Send On", route: "/" },
          { id: 26, name: "Exchange", route: "/" },
          // { id: 22, name: "MOD VAL", route: "/" },
          // { id: 22, name: "Source", route: "/" },
          // { id: 22, name: "Delivery Type", route: "/" },
        ],
      },
      {
        name: "Rate Limits",
        children: [
          { id: 30, name: "Introduction    ", route: "/" },
        ],
      },
      // {
      //   name: "Reference Libraries",
      //   children: [
      //     { id: 31, name: "Libraries and SDKs", route: "/" },
      //   ],
      // },
      // {
      //   name: "Terms & Conditions",
      //   children: [
      //     { id: 32, name: "Prohibitions and confidentiality", route: "/" },
      //     { id: 33, name: "Liability of the APIs", route: "/" },
      //   ],
      // },
    ],

  }),

  computed: {
    selected() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      return this.users.find((user) => user.id === id);
    },
    todayDate() {
      return this.formatDate(new Date().toISOString().split('T')[0]);
    },
  },

  mounted() {
    localStorage.setItem("Menutabs", JSON.stringify(this.tree))
  },

  methods: {
    test(route) {
      if (
        route[0] == "Introduction" ||
        route[0] == "API Overflow" ||
        route[0] == "Generate API Key"
      ) {
        this.$router.push("/introduction").catch(() => { });
      } else if (
        route[0] == "API Encryption Key" ||
        route[0] == "SessionId" ||
        route[0] == "Logout From API" ||
        route[0] == "Logout From All Devices" || route[0] == "Note Auth"
      ) {
        this.$router.push("/authentication").catch(() => { });
      } else if (
        route[0] == "Search Scrips" ||
        route[0] == "Market Watch List" ||
        route[0] == "Market Watch Scrips" ||
        route[0] == "Add scrips" ||
        route[0] == "Delete scrips" ||
        route[0] == "Scrips details" || route[0] == "Note MW"
      ) {
        this.$router.push("/marketWatch").catch(() => { });
      } else if (
        route[0] == "Portfolio" ||
        route[0] == "Position Book" ||
        route[0] == "Holdings" || route[0] == "Note Portfolio"
      ) {
        this.$router.push("/portfolio").catch(() => { });
      } else if (
        route[0] == "Position Convertion" ||
        route[0] == "Square of Position" ||
        route[0] == "Place Order" ||
        route[0] == "Bracket Order" ||
        route[0] == "Order Book" ||
        route[0] == "Trade Book" ||
        route[0] == "Exit Bracket Order" ||
        route[0] == "Modify Order" ||
        route[0] == "Market Order" ||
        route[0] == "Cancel Order" ||
        route[0] == "Order History" || route[0] == "Note OM"
      ) {
        this.$router.push("/orderManagement").catch(() => { });
      } else if (route[0] == "Get Limits" || route[0] == "Note Funds") {
        this.$router.push("/funds").catch(() => { });
      }
      //  else if (route[0] == "Profile" || route[0] == "Account Details") {
      //   this.$router.push("/profile").catch(() => { });
      // } 
      else if (
        route[0] == "Registration" ||
        route[0] === "Introduction " ||
        route[0] == "SSO"
      ) {
        this.$router.push("/vendors").catch(() => { });
      } else if (
        route[0] == "HTML 5 Buttons" ||
        route[0] == "Brand Button" ||
        route[0] == "Custom Button" ||
        route[0] == "Basket Button"
      ) {
        this.$router.push({ name: 'htmlButtons' }).catch(() => { });
      } else if (
        route[0] == "Transaction Type" ||
        route[0] == "Price Type" ||
        route[0] == "Product Type" ||
        route[0] == "Segment Type" ||
        route[0] == "Send On" ||
        route[0] == "Exchange" ||
        route[0] == "MOD VAL" ||
        route[0] == "Source" ||
        route[0] == "Delivery Type"
      ) {
        this.$router.push("/appendix").catch(() => { });
      } else if (
        route[0] == "Contract Master" ||
        route[0] == "JSON Format" ||
        route[0] == "CSV Format" ||
        route[0] == "JSON API Request" || route[0] == "INDICES API Request" || route[0] == "INDICES ZIP Format" || route[0] == "INDICES CSV Format"
      ) {
        this.$router.push("/contractMaster").catch(() => { });
      } else if (route[0] == "Websocket" || route[0] == "Create Session" ||
        route[0] == "Create Connection" || route[0] == "Subscribe to Depth" ||
        route[0] == "Unsubscribe to Depth" || route[0] === "Introduction  " || route[0] == "Subscribe to Market Data" ||
        route[0] == "Unsubscribe to Market Data") {
        this.$router.push("/websocket").catch(() => { });
      }
      else if (route[0] === "Data API") {
        this.$router.push("/historical").catch(() => { });
      }
      else if (route[0] === "Introduction    ") {
        this.$router.push("/rates").catch(() => { });
      } else if (route[0] === "Profile" || route[0] === "Account Details") {
        this.$router.push("/profile").catch(() => { });
      } else if (route[0] === 'Reference Libraries' || route[0] === 'Libraries and SDKs') {
        this.$router.push("/libraries").catch(() => { });
      } else if (route[0] === 'Terms & Conditions' || route[0] === 'Prohibitions and confidentiality' || route[0] === 'Liability of the APIs') {
        this.$router.push("/Terms & Conditions").catch(() => { });
        // this.$router.push({ name: 'terms' })
      }
      this.$store.dispatch("setActiveTab", route[0]);
    },
    navigate(val, type) {
      type == "head" ? (this.model = 0) : "";
      if (
        val == "Introduction" ||
        val == "API Overflow" ||
        val == "Generate API Key"
      ) {
        this.$router.push("/introduction").catch(() => { });
      } else if (
        val == "Authentication" ||
        val == "API Encryption Key" ||
        val == "SessionId" ||
        val == "Logout From API" ||
        val == "Logout From All Devices"
      ) {
        this.$router.push("/authentication").catch(() => { });
      } else if (
        val == "Market Watch" ||
        val == "Search Scrips" ||
        val == "Market Watch List" ||
        val == "Market Watch Scrips" ||
        val == "Add scrips" ||
        val == "Delete scrips" ||
        val == "Scrips details"
      ) {
        this.$router.push("/marketWatch").catch(() => { });
      } else if (
        val == "Portfolio" ||
        val == "Position Book" ||
        val == "Holdings"
      ) {
        this.$router.push("/portfolio").catch(() => { });
      } else if (
        val == "Order Management" ||
        val == "Position Convertion" ||
        val == "Square of Position" ||
        val == "Place Order" ||
        val == "Bracket Order" ||
        val == "Order Book" ||
        val == "Trade Book" ||
        val == "Exit Bracket Order" ||
        val == "Modify Order" ||
        val == "Market Order" ||
        val == "Cancel Order" ||
        val == "Order History"
      ) {
        this.$router.push("/orderManagement").catch(() => { });
      } else if (val == "Get Limits" || val == "Funds") {
        this.$router.push("/funds").catch(() => { });
      } else if (val == "Profile" || val == "Account Details") {
        console.log(val)
        this.$router.push("/profile").catch(() => { });
      } else if (
        val == "Vendors" ||
        val == "Registration" ||
        val == "Introduction " ||
        val == "SSO"
      ) {
        this.$router.push("/vendors").catch(() => { });
      } else if (
        val == "HTML 5 Buttons" ||
        val == "Brand Button" ||
        val == "Custom Button" ||
        val == "Basket Button"
      ) {
        this.$router.push({ name: 'htmlButtons' }).catch(() => { });
      } else if (
        val == "Appendix" ||
        val == "Transaction Type" ||
        val == "Price Type" ||
        val == "Product Type" ||
        val == "Segment Type" ||
        val == "Send On" ||
        val == "Exchange" ||
        val == "MOD VAL" ||
        val == "Source" ||
        val == "Delivery Type"
      ) {
        this.$router.push("/appendix").catch(() => { });
      }
      this.$store.dispatch("setActiveTab", val);
    },
    onOpen() {
      if (!this.allOpened) {
        this.allOpened = true;
        this.$refs.tree.updateAll(false);
      }
    },
    onMobileSize() {
      window.innerWidth <= 1023
        ? this.$store.commit("setMobileView", true)
        : this.$store.commit("setMobileView", false);
    },
    callTo(data) {
      console.log(data);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}${month}${year}`;
    },
  },
};
</script>

<style>
.bg-active {
  background-color: #e4edf8;
  color: #1867c0 !important;
}
</style>